/** Worker Sagas */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import { abi } from "./utils/abi";
import { BigNumber, ethers } from "ethers";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

/** List Saga
 *  @description: connects to the getContract operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Contracts.listContractsForProject,
      { project_id: projectId, page: pageNum, items: itemNum },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let {
    project_id,
    title,
    public_description,
    secret_description,
    content_category_id,
    content_type,
    content_status,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Contracts.createContractForProject,
      { project_id: project_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          title: title,
          public_description: public_description,
          secret_description: secret_description,
          content_type: content_type,
          status: content_status,
          content_category_id: content_category_id,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
/** Show Saga
 *  @description: connects to the showContract operation
 *  @param {number} action.payload the Contract id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, contractAddress } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Contracts.showContractForProject,
      {
        project_id: projectId,
        address: contractAddress,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* update(action) {
  const {
    project_id,
    id,
    title,
    public_description,
    secret_description,
    content_category_id,
    content_type,
    content_status,
  } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Contracts.updateContractForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          title: title,
          public_description: public_description,
          secret_description: secret_description,
          content_type: content_type,
          status: content_status,
          content_category_id: content_category_id,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

export function* genericAbiWrite(action) {
  const {
    successCallbackFnc,
    errorCallbackFnc,
    functionAbi,
    contractAddress,
    genericInputs,
    payableValue,
    transactionCallbackFnc,
  } = action.payload;

  if (window.ethereum) {
    const ethersProvider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );
    let contract = new ethers.Contract(
      contractAddress,
      [functionAbi],
      ethersProvider.getSigner()
    );

    let genericParameters = [];
    genericParameters = functionAbi.inputs.map((input) => {
      return genericInputs[input.name];
    });
    const transactionParams = {};
    if (functionAbi.stateMutability === "payable")
      transactionParams.value = payableValue;

    try {
      const functionResult = yield contract[functionAbi.name](
        ...genericParameters,
        transactionParams
      );
      console.log("success", functionResult);
      if (successCallbackFnc) successCallbackFnc(functionResult);
      if (functionResult?.hash)
        ethersProvider.once(functionResult?.hash, (transaction) => {
          // Emitted when the transaction has been mined
          console.log("transaction", transaction);
          transactionCallbackFnc(transaction);
        });
      yield put({
        type: actions.genericAbiWriteSuccess,
        payload: { abi: functionAbi, data: functionResult },
      });
      yield put({
        type: actions.transactionSuccess,
        payload: { abi: functionAbi, data: transaction },
      });
    } catch (data) {
      const err = { data };
      let errorMessage = false;
      if (err?.data?.error?.message) {
        errorMessage = err?.data?.error?.message;
      } else if (data?.message) {
        errorMessage = data.message;
      } else {
        errorMessage =
          "An Unkown Error occurred. We are not able to mint with your wallet. Please check that you are connected to the right Ethereum Network and in doubt refresh this page.";
      }
      if (errorMessage && errorCallbackFnc) errorCallbackFnc(errorMessage);
      yield put({
        type: actions.genericAbiWriteError,
        payload: { abi: functionAbi, data: data, errorMessage: errorMessage },
      });
    }
  }
}

export function* genericAbiRead(action) {
  const {
    successCallbackFnc,
    errorCallbackFnc,
    functionAbi,
    contractAddress,
    genericInputs,
  } = action.payload;

  if (functionAbi.type === "static") {
    if (successCallbackFnc) successCallbackFnc(functionAbi.data);
  } else if (window.ethereum) {
    const ethersProvider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );

    let contract = new ethers.Contract(
      contractAddress,
      [functionAbi],
      ethersProvider.getSigner()
    );

    let genericParameters = [];
    genericParameters = functionAbi.inputs.map((input) => {
      return genericInputs[input.name || "key"]; // For Reads on Mappings we need to pass a key.
    });

    try {
      const functionResult = yield contract[functionAbi.name](
        ...genericParameters
      );
      console.log("success", functionResult);
      if (successCallbackFnc) successCallbackFnc(functionResult);
      yield put({
        type: actions.genericAbiReadSuccess,
        payload: { abi: functionAbi, data: functionResult },
      });
    } catch (data) {
      const err = { data };
      let errorMessage = false;
      if (err?.data?.error?.message) {
        errorMessage = err?.data?.error?.message;
      } else if (data?.message) {
        errorMessage = data.message;
      } else {
        errorMessage =
          "An Unkown Error occurred. We are not able to mint with your wallet. Please check that you are connected to the right Ethereum Network and in doubt refresh this page.";
      }
      if (errorMessage && errorCallbackFnc) errorCallbackFnc(errorMessage);
      yield put({
        type: actions.genericAbiReadError,
        payload: { abi: functionAbi, data: data, errorMessage: errorMessage },
      });
    }
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* contractsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.genericAbiWrite, genericAbiWrite);
  yield takeEvery(actions.genericAbiRead, genericAbiRead);
}
export default contractsSaga;
