import { injected } from "./Web3Connectors";

async function keepWalletConnected(account, activate) {
  injected
    .isAuthorized()
    .then((authorized) => {
      if (authorized) activate(injected);
    })
    .catch((e) => console.log(e));
}
export default keepWalletConnected;
