/** ----- apiSwaggerRequest.js -----
 *  File: apiSwaggerRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling through swagger client
 *----------------------------- */
import Swagger from "swagger-client";
const API_URL = process.env.REACT_APP_API_SERVER;

/**
 * The swagger client. We make this call to load the swagger json
 */
const swagger = () => {
  const url = API_URL || "http://localhost:3000";
  return Swagger({ url: `${url}/api-docs/v1/swagger.json` });
};

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
export const applyHeaders = (headers) => (req) => {
  if (headers) {
    Object.keys(headers).map(
      (headerKey) => (req.headers[headerKey] = headers[headerKey])
    );
  }
  return req;
};

export default swagger;
