/** ----- buildHeaders.js -----
 *  File: buildHeaders.js
 *
 *  Purpose: providing helper functions to set headers for api requests
 *
 *-----------------------------*/
import Cookies from "universal-cookie";
import { ethers } from "ethers";
const cookieHandler = new Cookies();

async function buildHeaders() {
  let creatorToken = cookieHandler.get("creator-token");
  let walletToken = cookieHandler.get("wallet-token");
  let signer = null;
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = await provider.getSigner().getAddress();
  } catch (e) {
    console.log("Provider missing");
  }
  const headers = {
    "Content-Type": "application/json",
  };
  if (creatorToken) headers["creator-token"] = creatorToken;
  if (walletToken) headers["wallet-token"] = walletToken;
  if (signer) headers["uid"] = signer;
  return headers;
}

export default buildHeaders;
