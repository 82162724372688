/**
 *  Sagas for the user account management
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a succes or a failure action
 */
import { call, put, takeEvery, delay, select } from "redux-saga/effects";
import actions from "./actions";
import servWorkActions from "../servWork";
import Swagger from "swagger-client";
import { push } from "connected-react-router";
import notificationActions from "../notifications";
import buildHeaders from "../../utils/buildHeaders";
const API_URL = process.env.REACT_APP_API_SERVER;
const WEB_URL = process.env.REACT_APP_WEB_SERVER;

/** Worker Sagas */

/** Login Saga
 *  @description: passes username and password to the `auth/sign_in` to login the user
 *  - redirects to dashboard on success
 *  - sends push subscription data to the server after success
 *  @param {Object} action.payload username and password
 */
export function* login(action) {
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth/sign_in`, {
      method: "POST",
      body: JSON.stringify(action.payload),
      headers: { "Content-Type": "application/json" },
    });
    yield put({ type: actions.loginSuccess, payload: payload });
    yield put({ type: servWorkActions.sendPushSubscription });
    yield put(push("/dashboard"));
  } catch (e) {
    yield put({ type: actions.loginFail, error: e });
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  }
}

/** Logout Saga
 *  @description: connects to auth/signout endpoint to log out the user
 *  - redirects to landing
 *  @param {Object} action.payload the user tokens
 */
export function* logout(action) {
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth/sign_out`, {
      method: "DELETE",
      body: JSON.stringify(action.payload),
      headers: { "Content-Type": "application/json" },
    });
    yield put({ type: actions.logoutSuccess, payload: payload });
    yield put(push("/"));
  } catch (e) {
    yield put({ type: actions.logoutFail, error: e });
  }
}

/** Social Login Saga
 *  @description: connects to auth/facebook endpoint to login the user with social data
 *  @param {Object} action.payload the user tokens
 */
export function* socialLogin() {
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth/facebook`);
    yield put({ type: actions.loginSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.loginFail, error: e });
  }
}

/** Register Saga
 *  @description: registers a new user in wait for confirmation
 *  @param {Object} action.payload name, email, password, confirmation, avatar
 */
export function* register(action) {
  let values = action.payload;
  let body = JSON.stringify(values);
  let headers = { "Content-Type": "application/json" };
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth`, {
      method: "POST",
      body,
      headers,
    });
    yield put({ type: actions.registerSuccess, payload: payload });
    yield put(push("/account/login"));
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  } catch (e) {
    yield put({ type: actions.registerFail, error: e });
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  }
}

/** Recovery Saga
 *  @description: sends a password recovery request for a user
 *  - redirects to login
 *  @param {Object} action.payload the email address to recover
 */
export function* pwdRecovery(action) {
  action.payload.redirect_url = `${WEB_URL}/account/reset`;
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth/password`, {
      method: "POST",
      body: JSON.stringify(action.payload),
      headers: { "Content-Type": "application/json" },
    });
    yield put({ type: actions.pwdRecoverySuccess, payload: payload });
    yield put(push("/account/login"));
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  } catch (e) {
    yield put({ type: actions.pwdRecoveryFail, error: e });
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  }
}

/** Reset Saga
 *  @description: sends a password reset request for a user
 *  - redirects to dashboard
 *  @param {Object} action.payload the authentication tokens
 */
export function* pwdReset(action) {
  var body = action.payload;
  var headers = {
    "Content-Type": "application/json",
    "access-token": body.headers["access-token"],
    client: body.headers.client,
    uid: body.headers.uid,
  };
  delete body.headers;
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth/password`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: headers,
    });
    yield put({ type: actions.pwdResetSuccess, payload: payload });
    yield put(push("/"));
    let alertOptions = {
      message: `Password resetted correctly! `,
      color: "success",
      origin: "sagas",
    };
    yield put({ type: notificationActions.showAlert, payload: alertOptions });
    yield delay(3000);
    yield put({ type: notificationActions.hideAlert });
  } catch (e) {
    yield put({ type: actions.pwdResetFail, error: e });
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  }
}

/** Update Saga
 *  @description: connects to the update profile operation
 */
export function* updateProfile(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const payload = yield call(authApiRequest, `${API_URL}/auth`, {
      method: "PUT",
      body: JSON.stringify(action.payload),
      headers: headers,
    });
    // const SwaggerClient = yield call(apiClient)
    // const payload = yield call(SwaggerClient.apis.Users.updateUser, {body: action.payload}, {requestInterceptor: applyHeaders(headers)})
    yield put({ type: actions.updateProfileSuccess, payload: payload });
    let alertOptions = {
      message: `Your profile has been updated successfully`,
      color: "success",
      origin: "sagas",
    };
    yield put({ type: notificationActions.showAlert, payload: alertOptions });
    yield delay(3000);
    yield put({ type: notificationActions.hideAlert });
  } catch (e) {
    yield put({ type: actions.updateProfileFail, payload: e });
    yield delay(5000);
    yield put({ type: actions.clearErrors });
  }
}

/** The saga watchers */
function* authSaga() {
  yield takeEvery(actions.login, login);
  yield takeEvery(actions.register, register);
  yield takeEvery(actions.socialLogin, socialLogin);
  yield takeEvery(actions.logout, logout);
  yield takeEvery(actions.pwdRecovery, pwdRecovery);
  yield takeEvery(actions.pwdReset, pwdReset);
  yield takeEvery(actions.updateProfile, updateProfile);
}

export default authSaga;

/**
 * Custom api request for authentication methods
 * @param {string} _url the endpoint url
 * @param {*} args the options object holding headers, body and method
 */
const authApiRequest = (_url, args) => {
  const request = {
    url: _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  };
  return Swagger.http(request)
    .then((res) => {
      var result = res.body;
      if (result && result.data) {
        result.data.tokens = res.headers;
      }
      return result;
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.obj) throw err.response.obj;
      throw err;
    });
};
