/**
 * The service worker configuration util
 */
import servWorkActions from "../redux/servWork";
import notificationsActions from "../redux/notifications";

/**
 * builds the service worker configurations object
 */
export default function configureWorker(store) {
  const onUpdate = () => {
    let alertOptions = {
      message: "Update",
      color: "success",
      link: "reload",
      origin: "service worker",
    };
    store.dispatch(notificationsActions.showAlert(alertOptions));
  };
  const onPushSubscribed = (pushSubscription) => {
    store.dispatch(servWorkActions.storePushSubscription(pushSubscription));
    store.dispatch(servWorkActions.sendPushSubscription(pushSubscription));
  };
  return { onUpdate, onPushSubscribed };
}
