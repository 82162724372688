/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { ethers } from "ethers";
import generateNonce from "./utils/generateNonce";

export function* list(action) {
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(SwaggerClient.apis.Wallets.listWallets, {});

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

/** Show Saga
 *  @description: connects to the showResourceName operation
 *  @param {number} action.payload the ResourceName id
 */
export function* show(action) {
  const walletId = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Wallets.showWallet,
      { id: walletId },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  let headers = {};
  let { account } = action.payload;
  console.log(account);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Wallets.createWallets,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          address: account,
        },
      }
    );

    yield storeIncluded({ payload: payload.obj.included });
    yield* put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* siwe(action) {
  let headers = yield buildHeaders();
  let { account, nonce, nonceIssuedAt, callbackSuccess } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Wallets.walletSIWE,
      {
        address: account,
      },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          nonce: nonce,
          nonce_issued_at: nonceIssuedAt,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.siweSuccess, payload: payload.obj.data });
    if (callbackSuccess) callbackSuccess();
  } catch (e) {
    yield put({ type: actions.siweFail, payload: e });
  }
}

export function* prepareSiwe(action) {
  let { account, callbackSuccess } = action.payload;
  try {
    const nonce = generateNonce(8);
    const nonceIssuedAt = new Date().toUTCString();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    // TODO: This should be changeable for whitelabled apps.
    const signature = yield signer.signMessage(
      `bowline.app wants you to sign in with your Ethereum account:
${account}

Thanks for using bowline.app! Sign this message to login. No password needed!

I hereby accept the Terms of Service: https://bowline.app/tos

URI: https://bowline.app
Version: 1
Chain ID: 1
Nonce: ${nonce}
Issued At: ${nonceIssuedAt}
`
    );

    yield put({ type: actions.prepareSiweSuccess, payload: { signature } });
    callbackSuccess({
      nonce: nonce,
      nonceIssuedAt: nonceIssuedAt,
    });
  } catch (e) {
    yield put({ type: actions.prepareSiweFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* walletSaga() {
  yield takeEvery(actions.prepareSiwe, prepareSiwe);
  yield takeEvery(actions.siwe, siwe);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}
export default walletSaga;
