/**
 * The navbar container
 */
import React from "react";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { Navbar } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import FlashAlert from "../FlashAlert";
import { NavLink } from "react-router-dom";
import TrunkatedAddress from "../Utils/TrunkatedAddress";

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  alertOrigin: state.notifications.origin,
  alertLink: state.notifications.link,
  lang: state.i18nState.lang,
  currentUser: state.account.currentUser,
  signedin: state.account.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      navigateTo: push,
    },
    dispatch
  );

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  const { alertMessage, alertColor, alertTimeout, alertLink, alertOrigin } =
    props;
  const { account } = useWeb3React();

  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar color="transparent" expand="sm">
        {/* <NavbarToggler aria-label="navigation_toggle" onClick={toggle} /> */}
        <NavLink to="/" className="navbar-brand">
          <h3 className="fw-bold text-white">
            {context.t("WeDream Founders Pass")}
          </h3>
        </NavLink>
        {!account ? (
          <NavLink
            to="/onboarding/metamask"
            id="nav-connect-wallet"
            className="btn btn-outline-nav px-4 py-2 my-2 text-white rounded-pill"
          >
            {context.t("Connect to Wallet")}
          </NavLink>
        ) : (
          <span className="text-white border border-secondary py-3 px-3 fw-bold">
            <TrunkatedAddress address={account} />
          </span>
        )}
      </Navbar>
      {alertMessage && (
        <FlashAlert
          color={alertColor || "success"}
          timeout={alertTimeout}
          origin={alertOrigin}
          link={alertLink}
        >
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

Navigationbar.propTypes = {
  navigateTo: PropTypes.func,
  handleLogoutAction: PropTypes.func,
  lang: PropTypes.string,
  currentUser: PropTypes.object,
  signedin: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertColor: PropTypes.string,
  alertTimeout: PropTypes.bool,
  isSticky: PropTypes.bool,
  style: PropTypes.object,
  alertLink: PropTypes.string,
  alertOrigin: PropTypes.string,
};

Navigationbar.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
