/**
 * The Root container
 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Loadable from "react-loadable";
import { useWeb3React } from "@web3-react/core";
import FourOFour from "./FourOFour";
import keepWalletConnected from "../components/ConnectWalletButton/utils/KeepWalletConnected";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

/**
 * Loadable import for Home container
 */
// const Landingpage = Loadable({
//   loader: () => import(/* webpackChunkName: "Landingpage" */ './Landingpage'),
//   loading: () => null,
//   modules: ['Landingpage']
// });

// /**
//  * Loadable import for About container
//  */
// const About = Loadable({
//   loader: () => import(/* webpackChunkName: "About" */ '../components/About'),
//   loading: () => null,
//   modules: ['About']
// });

// /**
//  * Loadable import for Dashboard container
//  */
// const Dashboard = Loadable({
//   loader: () => import(/* webpackChunkName: "Dashboard" */ './Dashboard'),
//   loading: () => null,
//   modules: ['Dashboard']
// });

// const UserProfile = Loadable({
//   loader: () => import(/* webpackChunkName: "UserProfile" */ './UserProfile'),
//   loading: () => null,
//   modules: ['UserProfile']
// });

// const Dashboard = Loadable({
//   loader: () => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"),
//   loading: () => null,
//   modules: ["Dashboard"],
// });

// const Whitelist = Loadable({
//   loader: () => import(/* webpackChunkName: "Whitelist" */ "./Whitelist"),
//   loading: () => null,
//   modules: ["Whitelist"],
// });

// const EventsRoot = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "EventsRoot" */ "./Events/EventsRoot"),
//   loading: () => null,
//   modules: ["EventsRoot"],
// });

// const UnlockableContentsRoot = Loadable({
//   loader: () =>
//     import(
//       /* webpackChunkName: "UnlockableContentsRoot" */ "./UnlockableContents/UnlockableContentsRoot"
//     ),
//   loading: () => null,
//   modules: ["UnlockableContentsRoot"],
// });

// const VotingsRoot = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "VotingsRoot" */ "./Votings/VotingsRoot"),
//   loading: () => null,
//   modules: ["Votings"],
// });

// const PartnerDealsRoot = Loadable({
//   loader: () =>
//     import(
//       /* webpackChunkName: "PartnerDeals" */ "./PartnerDeals/PartnerDealsRoot"
//     ),
//   loading: () => null,
//   modules: ["PartnerDeals"],
// });

// const TokensRoot = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "TokensRoot" */ "./Tokens/TokensRoot"),
//   loading: () => null,
//   modules: ["TokensRoot"],
// });

const OnBoarding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "OnBoarding" */ "./OnBoarding/OnBoarding-Root"),
  loading: () => null,
  modules: ["OnBoarding-Root"],
});

const MintingSectionRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MintingSection" */ "./Minting/MintingSectionRoot"
    ),
  loading: () => null,
  modules: ["MintingSection"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
function Root() {
  const { account, activate } = useWeb3React();
  useEffect(() => {
    keepWalletConnected(account, activate);
  }, [account, activate]);

  return (
    <StickyContainer id="Root">
      <Navbar />
      <div id="content" className="bg-gradient">
        <div id="randomTest"></div>
        <Switch>
          <Route path="/" exact component={MintingSectionRoot} />
          <Route path="/onboarding" component={OnBoarding} />
          {/* <Route path={"/whitelist"} component={Whitelist} />
          <Route path="/contents" component={UnlockableContentsRoot} />
          <Route path="/events" component={EventsRoot} />
          <Route path={"/votings"} component={VotingsRoot} />
          <Route path="/deals" component={PartnerDealsRoot} />
          <Route path="/tokens" component={TokensRoot} />
          <Route path="/minting" component={MintingSectionRoot} /> */}

          {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
          {/* new routes */}

          <Route component={FourOFour} />
        </Switch>
      </div>
      <Footer />
    </StickyContainer>
  );
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = () => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
