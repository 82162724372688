/**
 * The navbar container
 */
import React from "react";
import PropTypes from "prop-types";
// import looksrare_logo from "../../assets/icons/looksrare.svg";
// import opensea_logo from "../../assets/icons/opensea.svg";
import discord_logo from "../../assets/icons/iconmonstr-discord-1.svg";
import instagram_logo from "../../assets/icons/iconmonstr-instagram-11.svg";
import twitter_logo from "../../assets/icons/iconmonstr-twitter-1.svg";
// import LogoBlack from "../../icons/LogoBlack.js";
import { Row, Col } from "reactstrap";

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Footer = (props, context) => {
  return (
    <div>
      <Row className="footer mt-3 align-items-center">
        <Col className="d-flex mx-5 justify-content-between">
          {/* <LogoBlack width={100} className="mx-5 logo" /> */}
          <div className="d-flex justify-content-start mx-3 w-25">
            <h3 className="text-dark align-items-start text-center mb-0 py-3">
              {context.t("WeDream")}
            </h3>
          </div>

          <div className="py-3 d-flex w-25 justify-content-center">
            <span className="text-dark">
              <p className="text-center align-items-center mb-0">
                © 2022 Busker AR, Inc. All rights reserved.
              </p>
            </span>
          </div>

          <div className="py-3 w-25 justify-content-end d-flex align-items-center text-center mx-3">
            <a
              href="https://discord.gg/wedream"
              target="_blank"
              rel="noreferrer"
            >
              <img className="ms-4" src={discord_logo} width={25} />
            </a>
            <a
              href="https://twitter.com/WeDream_World"
              target="_blank"
              rel="noreferrer"
            >
              <img className="ms-4" src={twitter_logo} width={25} />
            </a>
            <a
              href="https://www.instagram.com/wedream_world/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="ms-4" src={instagram_logo} width={25} />
            </a>
          </div>

          {/* <div className="my-3">
            <a
              href="https://opensea.io/collection/thewhitelistioaces"
              target="_blank"
              rel="noreferrer"
            >
              <img className="me-3" src={opensea_logo} width={20} />
            </a>
            <a
              href="https://looksrare.org/collections/0xB3B814cCd178De120687a9Ad01C6886C56399198"
              target="_blank"
              rel="noreferrer"
            >
              <img className="me-3" src={looksrare_logo} width={20} />
            </a>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Footer.contextTypes = {
  t: PropTypes.func,
};

export default Footer;
