/**
 * The app index
 */
import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Web3ReactProvider } from "@web3-react/core";
import Loadable from "react-loadable";
import I18n from "redux-i18n";
import createSsr from "@issr/core";
import Web3 from "web3";
import "./index.sass";
import "font-awesome/css/font-awesome.min.css";
import rootSaga from "./redux/rootSaga";
import configureStore, { isServer } from "./redux/store";
import configureWorker from "./utils/serviceWorkerConfig";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { translations } from "./locales/translations";
import Root from "./routes/Root";

/** create a store */
const { store, history } = configureStore();

/** run sagas */
store.runSaga(rootSaga);

// Web3 for connecting wallets
function getLibrary(provider) {
  return new Web3(provider);
}

const SSR = createSsr(window.SSR_DATA);

/** The application */
const Application = () => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <I18n translations={translations} initialLang="en">
        <ConnectedRouter history={history}>
          <Root />
        </ConnectedRouter>
      </I18n>
    </Provider>
  </Web3ReactProvider>
);

/** the root element to load react */
const root = document.querySelector("#root");

/**
 * the index main
 */
if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(
      <SSR>
        <Application />
      </SSR>,
      root
    );
  });
} else {
  // If we're not running on the server, just render like normal
  render(
    <SSR>
      <Application />
    </SSR>,
    root
  );
}

/** build the service worker configuration object */
var config = configureWorker(store);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!isServer) {
  serviceWorkerRegistration.register(config);
} else {
  serviceWorkerRegistration.unregister();
}
