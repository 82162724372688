import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import { ethers } from "ethers";

/** List Saga
 *  @description: connects to the getPartnerDeal operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, sorting, filters } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.PartnerDeals.listPartnerDealsForProject,
      {
        project_id: projectId,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { project_id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.PartnerDeals.createPartnerDealForProject,
      { project_id: project_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
/** Show Saga
 *  @description: connects to the showPartnerDeal operation
 *  @param {number} action.payload the PartnerDeal id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.PartnerDeals.showPartnerDealForProject,
      {
        project_id: projectId,
        id: id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* update(action) {
  const { project_id, id, ...requestBody } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.PartnerDeals.updatePartnerDealForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

export function* prepareUnlock(action) {
  let {
    account,
    token_identifier,
    unlock_boundary_id,
    project_title,
    contract_address,
    title,
    callbackSuccess,
  } = action.payload;
  try {
    //  Maybe lets add an issued at to the message and use this date also for unlocked_at in the api
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = yield signer.signMessage(
      `I want to retrieve the "${title}" deal for ${project_title}
with my NFT #${token_identifier} (Contract: ${contract_address}).

This message was signed with my Ethereum account:
${account}

URI: https://bowline.app
Version: 1
Chain ID: 1
`
    );

    yield put({ type: actions.prepareUnlockSuccess, payload: {} });
    callbackSuccess({
      signature,
      token_identifier,
      contract_address,
      unlock_boundary_id,
    });
  } catch (e) {
    yield put({ type: actions.prepareUnlockFail, payload: e });
  }
}

export function* unlock(action) {
  let headers = yield buildHeaders();
  let {
    project_id,
    id,
    token_identifier,
    contract_address,
    unlock_boundary_id,
    signature,
    callbackSuccess,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.PartnerDeals.unlockPartnerDealForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          signature,
          tokens_attributes: [
            {
              identifier: token_identifier,
              contract_address,
            },
          ],
          unlock_boundary_id: unlock_boundary_id,
          unlock: {
            signature,
            tokens_attributes: [
              {
                identifier: token_identifier,
                contract_address,
              },
            ],
            unlock_boundary_id: unlock_boundary_id,
          },
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    // TODO: reevaluate if showSuccess is right to call here, but doesn't feel to wrong to store the data
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
    yield put({ type: actions.unlockSuccess, payload: payload.obj.data });
    if (callbackSuccess) callbackSuccess();
  } catch (e) {
    yield put({ type: actions.unlockFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* partnerDealsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.prepareUnlock, prepareUnlock);
  yield takeEvery(actions.unlock, unlock);
}
export default partnerDealsSaga;
