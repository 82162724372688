/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { put, takeEvery, all } from "redux-saga/effects";
import { actions as projectActions } from "./projects";
import { actions as whitelistWalletActions } from "./whitelistWallets";
import { actions as walletActions } from "./wallets";
import { actions as tokenActions } from "./tokens";
import { actions as tokenHistoryEventActions } from "./tokenHistoryEvents";
import { actions as unlockableContentActions } from "./unlockableContents";
import { actions as partnerDealActions, partnerDeal } from "./partnerDeals";
import { actions as contentCategoryActions } from "./contentCategories";
import { actions as creatorActions } from "./creators";
import { actions as contractActions } from "./contracts";
import { actions as votingActions } from "./votings";
import { actions as votingOptionActions } from "./votingOptions";
import { actions as holderActions } from "./holders";
import { actions as eventActions } from "./events";
import { actions as unlockBoundaryActions } from "./unlockBoundaries";
import { actions as traitTypeActions } from "./traitTypes";
import { actions as traitActions } from "./traits";
import { actions as traitTypesUnlockBoundariesActions } from "./traitTypesUnlockBoundaries";
import { actions as eventRegistrationsActions } from "./eventRegistrations";
import { actions as votesActions } from "./votes";
import { actions as unlockHistoryEventsActions } from "./unlockHistoryEvents";
import { actions as mintTierActions } from "./mintTiers";

// What to do for a new resource?
// 1.) add Import
// import {actions as resourceNameActions} from '../redux/contents';
// 2.) add store included handlinge
// } else if (entry.type === "resource_name"){
//   return put({type: resourceNameActions.addToDictionary, payload: entry});
// }

/** Worker Sagas */
export function* storeIncluded(action) {
  yield all(
    action?.payload?.map((entry) => {
      if (entry.type === "project") {
        return put({ type: projectActions.addToDictionary, payload: entry });
      } else if (entry.type === "whitelist_entry") {
        return put({
          type: whitelistWalletActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "creator") {
        return put({ type: creatorActions.addToDictionary, payload: entry });
      } else if (entry.type === "wallet") {
        return put({ type: walletActions.addToDictionary, payload: entry });
      } else if (entry.type === "token") {
        return put({ type: tokenActions.addToDictionary, payload: entry });
      } else if (entry.type === "token_history_event") {
        return put({
          type: tokenHistoryEventActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "contract") {
        return put({ type: contractActions.addToDictionary, payload: entry });
      } else if (entry.type === "holder") {
        return put({ type: holderActions.addToDictionary, payload: entry });
      } else if (entry.type === "unlockable_content") {
        return put({
          type: unlockableContentActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "partner_deal") {
        return put({
          type: partnerDealActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "content_category") {
        return put({
          type: contentCategoryActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "voting") {
        return put({
          type: votingActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "voting_option") {
        return put({
          type: votingOptionActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "event") {
        return put({ type: eventActions.addToDictionary, payload: entry });
      } else if (entry.type === "unlock_boundary") {
        return put({
          type: unlockBoundaryActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "trait_type") {
        return put({
          type: traitTypeActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "trait") {
        return put({
          type: traitActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "trait_types_unlock_boundary") {
        return put({
          type: traitTypesUnlockBoundariesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "event_registration") {
        return put({
          type: eventRegistrationsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "vote") {
        return put({
          type: votesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "unlock_history_event") {
        return put({
          type: unlockHistoryEventsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "mint_tier") {
        return put({
          type: mintTierActions.addToDictionary,
          payload: entry,
        });
      } else return null;
    })
  );
}

// // Example for Updating relationships, rarely needed
// export function* updateRelationships(action) {
//   yield all(
//     action?.payload?.map((entry) => {
//       for (const rela in entry.relationships) {
//         const relationship = entry.relationships[rela];
//         if (relationship.data?.type === 'resource_name') {
//           return put({
//             type: resourceNameActions.addRelationship,
//             payload: {
//               id: relationship.data.id,
//               type: 'related_resource_name',
//               relationship: {id: entry.id, type: entry.type},
//             },
//           });
//         }
//       }
//       return null;
//     }),
//   );
// }

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* dictionarySagas() {
  yield takeEvery("dictionary/storeIncluded", storeIncluded);
  // yield takeEvery('dictionary/updateRelationships', updateRelationships);
}
export default dictionarySagas;
