/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const whitelistWalletsSlice = createSlice({
  name: "whitelistWallets",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
  },
});
export const {
  show: showWhitelistWallet,
  list: listWhitelistWallets,
  create: createWhitelistWallet,
  createReset: createWhitelistWalletReset,
  listReset: listWhitelistWalletReset,
  updateReset: updateWhitelistWalletReset,
} = whitelistWalletsSlice.actions;

export const actions = whitelistWalletsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const whitelistWallets = (state) =>
  state.whitelistWallets.idsList.map(
    (id) => state.whitelistWallets.dictionary[id]
  );
export const whitelistWallet = (state) =>
  state.whitelistWallets.showId &&
  state.whitelistWallets.dictionary[state.whitelistWallets.showId];
export const whitelistWalletAttributes = (state) =>
  whitelistWallet(state)?.attributes;
export const whitelistWalletRelationships = (state) =>
  whitelistWallet(state)?.relationships;
export const whitelistWalletId = (state) => state.whitelistWallets.showId;

export const getWhitelistWallet = createSelector(
  (state) => state.whitelistWallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getWhitelistWalletAttributes = createSelector(
  (state) => state.whitelistWallets.dictionary,

  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getWalletId = createSelector(
  (state) => state,
  (_, data) => data,
  (state, { address }) => {
    const walletId = Object.keys(state.wallets.dictionary).find((walletId) => {
      return state.wallets.dictionary[walletId].attributes.address === address;
    });
    return walletId;
  }
);

export const getWhitelistWalletAttributesForAddress = createSelector(
  (state) => state,
  (_, data) => data,
  (state, { address, projectId }) => {
    const walletId = Object.keys(state.wallets.dictionary).find((walletId) => {
      return state.wallets.dictionary[walletId].attributes.address === address;
    });
    const whitelistWalletId = Object.keys(
      state.whitelistWallets.dictionary
    ).find((whitelistWalletId) => {
      return (
        state.whitelistWallets.dictionary[whitelistWalletId].relationships
          .wallet.data.id === walletId &&
        state.whitelistWallets.dictionary[whitelistWalletId].relationships
          .project.data.id === projectId
      );
    });

    return state.whitelistWallets.dictionary[whitelistWalletId];
  }
);

export const getWhitelistWalletRelationships = createSelector(
  (state) => state.whitelistWallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getWhitelistWallets = createSelector(
  (state) => state.whitelistWallets.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default whitelistWalletsSlice.reducer;
