/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { i18nState } from "redux-i18n";
import servWork from "./servWork/reducers";
import notifications from "./notifications/reducers";
import account from "./account/reducers";
import projects from "./projects";
import whitelistWallets from "./whitelistWallets";
import wallets from "./wallets";
import tokens from "./tokens";
import tokenHistoryEvents from "./tokenHistoryEvents";
import contracts from "./contracts";
import creators from "./creators";
import holders from "./holders";
import unlockableContents from "./unlockableContents";
import partnerDeals from "./partnerDeals";
import contentCategories from "./contentCategories";
import votings from "./votings";
import votingOptions from "./votingOptions";
import events from "./events";
import unlockBoundaries from "./unlockBoundaries";
import traits from "./traits";
import traitTypes from "./traitTypes";
import traitTypesUnlockBoundaries from "./traitTypesUnlockBoundaries";
import eventRegistrations from "./eventRegistrations";
import votes from "./votes";
import unlockHistoryEvents from "./unlockHistoryEvents";
import mintTiers from "./mintTiers";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports all reducers through the combineReducers function */
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18nState,
    servWork,
    notifications,
    account,
    projects,
    whitelistWallets,
    wallets,
    holders,
    tokens,
    creators,
    unlockableContents,
    contentCategories,
    partnerDeals,
    contracts,
    votings,
    votingOptions,
    events,
    tokenHistoryEvents,
    unlockBoundaries,
    traits,
    traitTypes,
    traitTypesUnlockBoundaries,
    eventRegistrations,
    votes,
    unlockHistoryEvents,
    mintTiers,
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded reducers
  });

export default reducers;
